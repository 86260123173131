@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

.App {
  text-align: center;
}

/* App.css */
.App-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

* {
  font-family: "Inter", sans-serif;
}

.Content-container {
  flex: 1; /* Fills the available space between TopAppBar and Footer */
}

@media screen and (min-width: 768px) {
  .App {
    height: 100vh;
  }

  .Content-container {
    min-height: calc(100vh - 100px);
  }
}

body {
  font-family: "Inter", sans-serif;
}

.auth-container {
  box-shadow: 0px 0px 8px #00000015;
  border-radius: 12px;
  min-width: 320px;
  padding: 30px 40px 30px 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.auth-container .MuiInputBase-input {
  color: #333; /* Set the text color */
  background: #f2f2f2 !important;
  font-size: 12px;
  padding: 10px 8px;
}

.auth-container .MuiFormControl-root {
  background: #f2f2f2 !important;
}

.auth-container .MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-radius: 5px;
}

.auth-label {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}

@media screen and (max-width: 768px) {
  .auth-container .MuiInputBase-input {
    font-size: 16px;
  }
}

.auth-label-spam {
  font-size: 12px;
  font-weight: 300;
  color: #000000;
}

.auth-label-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.auth-login-btn {
  border-radius: 5px;
  background-color: #9f7fe3 !important;
  text-transform: none !important;
  font-weight: 600 !important;
}

.auth-login-btn:hover {
  background: #9f7fe3d8 !important;
}

.auth-bottom-nav {
  font-size: 12px;
  font-weight: 300;
  color: #000000;
  cursor: pointer;
  margin-top: 5px;
}

.auth-error-msg {
  font-size: 12px;
  font-weight: 400;
  color: #ff0000;
  max-width: 250px;
}

.auth-success-msg {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}

.auth-signup-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.cursor {
  cursor: pointer;
}

.text-logo {
  cursor: pointer;
  height: 32px;
}

.divider-shadow {
  box-shadow: 0px -4px 8px -4px #00000026;
}

.box {
  height: 33px;
  width: 123px;
  margin-left: -10px;
}

.categories-container {
  padding: 5px 5px 20px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  max-width: 1280px;
  gap: 20px;
  margin-left: auto;
  margin-right: auto;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  --webkit-scrollbar: {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .categories-container {
    margin-left: 20px;
    margin-right: 20px;
    width: auto;
    justify-content: center;
  }
}

.cards-container {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .cards-container {
    flex-direction: column;
    align-items: center;
  }
}

.create-project-tab {
  background-color: #ffffff;
  border-radius: 32px 32px 0px 0px;
  box-shadow: 0px 0px 8px #00000026;
  height: 33px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 7px;
}

.create-project-tab .text-wrapper {
  color: #9f7fe3 !important;
  text-align: center !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.box .rectangle {
  background-color: #ffffff;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px 0px 8px #00000026;
  height: 33px;
  width: 123px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box .rectangle .text-wrapper {
  color: #333333;
  font-size: 13px;
  font-weight: 400;
  height: 10px;
  letter-spacing: 0;
  line-height: 13px;
  text-align: center;
  white-space: nowrap;
}

.roles-container {
  height: 130px;
  overflow-y: scroll;
  text-align: left;
  margin-top: 20px;
  display: flex;
  gap: 15px;
  padding-left: 1rem;
  padding-right: 1rem;
  flex-direction: column;
  align-items: flex-start;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}

.roles-container::-webkit-scrollbar {
  display: none; /* Webkit */
}

.create-project-btn {
  background-color: #ffffff !important;
  border-radius: 20px !important;
  box-shadow: 0px 0px 4px #00000026;
  height: 30px;
  width: 100%;
  margin-right: 10px !important;
  color: #9f7fe3 !important;
  border: none !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  padding: 10px 12px 10px 12px !important;
  text-transform: none !important;
  cursor: pointer;

  .css-gcc2o7-MuiButton-startIcon {
    margin-right: 2px !important;
  }
}

.create-project-btn:hover {
  color: #ffffff !important;
}

.togather-btn {
  box-shadow: 0px 0px 4px #00000026;
  border-radius: 28.5px !important;
  cursor: pointer;
  background-color: #9f7fe3 !important;
  min-width: 300px;
  width: auto;
  height: auto;
  color: white !important;
  border: none !important;
  font-size: 16px;
  padding: 10px 18px 10px 18px !important;
}

.togather-btn:hover {
  background-color: #9f7fe3e6 !important;
}

.submit-proposal-btn {
  border-radius: 28.5px !important;
  cursor: pointer;
  background-color: #ffffff !important;
  max-width: 200px;
  width: auto;
  max-height: 30px;
  color: #9f7fe3 !important;
  border: none !important;
  font-size: 12px !important;
  padding: 6px 14px 6px 14px !important;
  border: 1px solid #9f7fe3 !important;
  margin-top: 2rem;
}

.submit-proposal-btn:hover {
  background-color: #9f7fe310 !important;
}

.category-item-class {
  width: auto !important;
  margin-right: 1rem;
}

.button-flex {
  background: #ffffff !important;
  border-radius: 28.5px !important;
  height: auto;
  width: 100%;
  color: #000000 !important;
  border: 1px solid #000000 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  padding: 10px 18px 10px 18px !important;
  text-transform: none !important;
  cursor: pointer;
}

.button-flex:hover {
  background-color: #f8f8f8 !important;
}

.create-project-btn:hover {
  background-color: #9f7fe3e6 !important;
}

.category-carousel {
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 6px;
}

.project-card-btn {
  background: #fff !important;
  border-radius: 28.5px !important;
  height: 30px;
  width: 110px;
  color: #333333 !important;
  border: none !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 42px !important;
  padding: 12px, 21px, 12px, 21px !important;
  text-transform: uppercase !important;
  border: 1px solid #9f7fe3 !important;
  box-shadow: 0px 0px 4px 0px #00000026;
}

.card-project {
  width: 300px;
  height: 412px;
  margin-bottom: 2px;
  border-radius: 30px !important;
  box-shadow: 0px 0px 8px 0px #00000026;
}

.grid-tile {
  border-radius: 30px;
  background: #e5e5e5;
  height: 120px;
  width: 120px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.grid-tile .icon {
  width: 40px;
  height: 40px;
}

.card-project .chip {
  background-color: #ffffff;
  color: #929292 !important;
  font-size: 10px;
}

.card-project .roles {
  color: #fff !important;
  text-align: left;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 11px !important;
  text-transform: capitalize;
  padding-top: 1px;
}

.card-media {
  width: 300px !important;
  height: 288px;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.card-media .title {
  color: #fff;
  font-size: 30px;
  font-weight: 900;
  line-height: 30px; /* 83.333% */
  text-transform: uppercase;
  padding-top: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  text-align: left;
  white-space: wrap; /* Prevent the text from wrapping */
  overflow: hidden; /* Hide the overflow text */
  text-overflow: ellipsis; /* Show ellipsis (...) */
  max-width: 275px; /* Set a max width for the container */
  display: inline-block; /* Make sure the element respects the max-width */
}

.card-media .owner {
  font-size: 13px;
  font-weight: 500;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.card-media .avatar {
  font-size: 12px;
  font-weight: 800;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: center;
  border: 2px solid #ffffff;
}

.card-project .description {
  color: #333;
  text-align: left;
  font-size: 10.385px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px; /* 125.175% */
  white-space: wrap; /* Prevents the text from wrapping to the next line */
  overflow: hidden; /* Hides text that overflows the container */
  text-overflow: ellipsis; /* Replaces the overflow text with an ellipsis */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* Number of lines you want to display */
  line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  margin-top: 0px !important;
}

.create-project {
  padding: 0px;
}

.create-project .back-btn {
  box-shadow: 0px 0px 4px #00000026;
  border-radius: 28.5px !important;
  cursor: pointer;
  width: auto;
  height: auto;
  color: #9f7fe3 !important;
  border: 1px solid #9f7fe3 !important;
  font-size: 16px;
  padding: 10px 18px 10px 18px !important;
  background-color: #ffffff;
}

.create-project .back-btn:hover {
  box-shadow: 0px 0px 6px #00000026;
}

.create-project .section {
  color: #000;
  text-align: left;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 120% */
  letter-spacing: -0.5px;
  text-transform: uppercase;
}

.create-project .title {
  color: #333;
  text-align: left;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 100% */
}

.create-project .MuiInputBase-input {
  color: #333; /* Set the text color */
  background: #f2f2f2 !important;
  font-size: 12px;
  padding: 7.5px 8px;
}

.create-project .MuiOutlinedInput-root {
  padding: 4px 4px 4px 10px !important;
}

.create-project .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
  margin-right: 0px;
}

.create-project .MuiAutocomplete-tag.link-chip {
  border-radius: 5px;
  border: 1px solid #b3b3b3;
  background: #fff;
  font-size: 12px;
}

.create-project .link-container {
  width: 100px;
  height: 80px;
  border-radius: 5px;
  background: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.create-project .link-container:hover {
  background: #d9d9d9c7;
}

.create-project .react-player {
  border-radius: 5px !important;
}
.create-project .MuiInputBase-root {
  background-color: #f2f2f2 !important;
  font-size: 12px;
}

.create-project .role {
  border-radius: 28.5px;
  padding: 20px 10px;
  color: white;
  background: #333;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
}

.create-project .MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-radius: 5px;
}

.custom-dialog .MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-radius: 5px;
}

.custom-dialog .MuiInputBase-root {
  background-color: #f2f2f2 !important;
  font-size: 12px;
}

.create-project .create-btn {
  border-radius: 5px;
  background: #9f7fe3;
  font-weight: 600;
}

.create-project .create-btn:hover {
  background: #9f7fe3d8;
}

.custom-dialog .dialog-btn {
  border-radius: 5px;
  background-color: #9f7fe3 !important;
}

.custom-dialog .dialog-btn:hover {
  background-color: #9f7fe3d8 !important;
}

.create-project .cancel-btn {
  color: #c1c1c1;
}

.create-project .cancel-btn:hover {
  color: #c1c1c1;
  background: white;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.center-screen {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.custom-shadow {
  box-shadow: 0px 19px 40px rgba(0, 0, 0, 0.05) !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #9f7fe3 !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #9f7fe3 !important;
}

.panel-br {
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.custom-margins {
  max-width: 1280px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.custom-margins-submissions {
  max-width: 1280px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 768px) {
  .custom-margins-submissions {
    width: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.project-view {
  padding-top: 2rem;
}

@media screen and (max-width: 768px) {
  .project-view {
    width: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
  }
}

.carousel-container {
  max-height: 380px;
  width: 100%;
  margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .carousel-container {
    display: flex;
  }
}

@media screen and (min-width: 769px) {
  .carousel-container {
    display: none !important;
  }
}

.carousel-item {
  width: 100%;
  max-height: 380px;
  height: 100%;
  cursor: pointer;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Or use "contain" depending on your desired behavior */
  max-width: 100%;
  max-height: 100%;
}

.map-container {
  height: 400px; /* Adjust the height as needed */
  width: 100%; /* Full width */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  overflow: hidden; /* To maintain border radius */
}

.display-flex {
  display: flex;
}

.MuiCard-root {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
}

.messageContainer:hover {
  background-color: #f9f9f9 !important;
  cursor: pointer;
}

.messageContainer {
  padding: 10px 30px 10px 30px;
  display: flex;
}

.noPadding {
  padding: 0 !important;
}

.messageHeader {
  padding: 0px 0px 0px 0px;
  display: flex;
  margin-bottom: 20px;
}

.senderBubble {
  border-radius: 25px;
  background: #e7e7e7;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 14px !important;
  padding-right: 14px !important;
  margin-top: 0x;
  margin-bottom: 8px;
  margin-right: 0px !important;
  margin-left: 0px !important;
  width: fit-content !important;
}
.senderContainer {
  display: flex;
  flex-direction: column;
  padding: none;
  align-items: right !important;
}

.recipientContainer {
  display: flex;
  flex-direction: column;
  padding: none;
  align-items: flex-end !important;
}

.recipientBubble {
  border-radius: 25px;
  background: #9f7fe3;
  color: white;
  margin-top: 16px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 14px !important;
  padding-right: 14px !important;
  margin-bottom: 8px;
  margin-right: 0px !important;
  margin-left: 0px !important;
  width: fit-content !important;
}

/* .sideTab {
} */

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #9f7fe3 !important;
}

.css-10d9dml-MuiTabs-indicator {
  background-color: #9f7fe3 !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  align-items: flex-start !important;
}

.child-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  transition: filter 0.1s ease; /* Smooth transition effect */
}

.child-img:hover {
  filter: brightness(90%); /* Adjust brightness value to control darkness */
}

.img-btn {
  position: relative;
  top: -42px;
  left: 29rem;
  background-color: white !important;
  color: black !important;
  border-color: black !important;
  border-radius: 10px;
}

.profile-image {
  width: 100px;
  height: 100px;
  border: 2px solid white;
  border-radius: 50%;
}

.ql-editor {
  min-height: 300px;
}

/* Add custom styles for font sizes */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="8px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="8px"]::before {
  content: "8px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
  content: "22px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
  content: "26px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="28px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
  content: "28px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="30px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
  content: "30px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="34px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="34px"]::before {
  content: "34px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="36px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
  content: "36px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="38px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="38px"]::before {
  content: "38px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="40px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="40px"]::before {
  content: "40px";
}

/* Define the style that will be applied when each font size is selected */
.ql-size-8px {
  font-size: 8px;
}

.ql-size-10px {
  font-size: 10px;
}

.ql-size-12px {
  font-size: 12px;
}

.ql-size-14px {
  font-size: 14px;
}

.ql-size-16px {
  font-size: 16px;
}

.ql-size-18px {
  font-size: 18px;
}

.ql-size-20px {
  font-size: 20px;
}

.ql-size-22px {
  font-size: 22px;
}

.ql-size-24px {
  font-size: 24px;
}

.ql-size-26px {
  font-size: 26px;
}

.ql-size-28px {
  font-size: 28px;
}

.ql-size-30px {
  font-size: 30px;
}

.ql-size-32px {
  font-size: 32px;
}

.ql-size-34px {
  font-size: 34px;
}

.ql-size-36px {
  font-size: 36px;
}

.ql-size-38px {
  font-size: 38px;
}

.ql-size-40px {
  font-size: 40px;
}

.upload-component {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  background-color: #f9f9f9;
}

.dropzone {
  border: 1px solid #9e9e9e;
  border-radius: 12px;
  text-align: center;
  padding: 20px;
  cursor: pointer;
  background-color: white;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Three columns of equal size */
  grid-auto-rows: 110px; /* Each row is 200px tall */
  gap: 6px;
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr 1fr; /* Single column on mobile */
  }
}

.first-item {
  grid-column: auto; /* Spanning the first 2 columns */
  grid-row: auto; /* Spanning 2 rows */
  width: 100%; /* Adjusted to fill the cell */
  height: 100%;
  object-fit: contain;
  object-position: center;
  cursor: pointer;
  transition: filter 0.1s ease;
  border: #ebebeb 1px solid;
  border-radius: 10px;
}

.other-item {
  grid-column: auto; /* Spanning 1 column */
  grid-row: auto; /* Spanning 1 row */
  width: 100%; /* Adjusted to fill the cell */
  height: 100%;
  object-fit: contain;
  object-position: center;
  cursor: pointer;
  transition: filter 0.1s ease;
  border: #ebebeb 1px solid;
  border-radius: 10px;
}

.image-item {
  height: auto;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 16/9;
  max-height: 210px;
}

.first-item .image-item {
  max-width: 160px; /* Width for first-item */
}

.other-item .image-item {
  max-width: 160px; /* Width for other-item */
}

/* Style for the container to allow smooth reordering of other items */
.grid-container .other-item {
  transition: transform 0.1s ease-out;
}

/* Style for the container to allow smooth reordering of other items */
.grid-container .first-item {
  transition: transform 0.1s ease-out;
}

.image-wrapper {
  position: relative;
  display: grid;
  place-items: center;
  height: 100%;
  /* ... your other styles ... */
}

.checkbox-container {
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 2; /* Ensures the checkbox is above the image */
  opacity: 0; /* Hide the checkbox by default */
  transition: opacity 0.2s ease; /* Smooth transition for the checkbox */
}

.image-wrapper:hover {
  /* Show the checkbox when the image wrapper is hovered */
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

/* Show the checkbox when the image wrapper is hovered */
.image-wrapper:hover .checkbox-container,
.image-wrapper.checked .checkbox-container {
  /* Added class for checked state */
  opacity: 1;
}

/* Optional: Add a slight background to the checkbox to improve visibility over the image */
.checkbox-container .MuiCheckbox-root {
  padding: 0px;
  background: none;
  border-radius: 4px; /* Optional for rounded corners */
}

/* Gradient overlay that will be applied when the image is selected */
.image-wrapper.checked::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#ffffff80, #ffffff80); /* Example gradient */
  z-index: 1; /* Ensure it's below the checkbox but above the image */
  border-radius: 4px; /* Optional for rounded corners */
}

.rec-type {
  font-weight: 400;
  text-transform: none;
  padding: 0 !important;
  font-size: 12px;
}

.sender-type {
  font-weight: 400;
  text-transform: none;
  padding: 0 !important;
  text-align: left;
  font-size: 12px;
}

.MuiChip-label {
  font-size: 10px;
}

.stepper-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: row;
  margin-bottom: 1rem;
}

.stepper-container .divider {
  background-color: #bdbdbd;
  width: 100%;
  max-width: 60px;
  min-width: 20px;
  height: 2px;
}

.stepper-container .avatar {
  background-color: #9f7fe3;
}

.team-avatar {
  font-size: 0.8rem !important;
  font-weight: 800 !important;
  line-height: 11px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
}

.apply-btn {
  color: #9f7fe3 !important;
}

.css-xdp26i-MuiButtonBase-root-MuiButton-root {
  color: #9f7fe3 !important;
}

.css-1xenrzq-MuiButtonBase-root-MuiButton-root {
  color: #9f7fe3 !important;
}

.css-13nn3nt-MuiButtonBase-root-MuiButton-root {
  color: #9f7fe3 !important;
}

@media screen and (max-width: 768px) {
  .settings {
    width: 90% !important;
  }
}

.settings-btn {
  border-radius: 10px !important;
  box-shadow: 0px 0px 4px #00000026;
  height: 43px;
  min-width: 100px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  letter-spacing: 1px !important;
  text-transform: none !important;
  border: none !important;
}

.settings-btn:hover {
  cursor: pointer;
}

.stripe-btn {
  border-radius: 10px !important;
  box-shadow: 0px 0px 4px #00000026;
  height: 43px;
  min-width: 200px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  letter-spacing: 1px !important;
  text-transform: none !important;
  border: none !important;
}

.stripe-btn:hover {
  cursor: pointer;
}

.settings .title {
  color: #333;
  text-align: left;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 100% */
}

.settings .MuiInputBase-input {
  color: #333; /* Set the text color */
  background: #f2f2f2 !important;
  font-size: 12px;
  padding: 7.5px 8px;
}

.settings .MuiOutlinedInput-root {
  padding: 4px 4px 4px 10px !important;
}

.settings .MuiInputBase-root {
  background-color: #f2f2f2 !important;
  font-size: 12px;
}

.settings .MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-radius: 5px;
}

.settings .css-1f3rzdg-MuiButtonBase-root-MuiButton-root {
  background-color: #9f7fe3 !important;
}

.settings-container {
  padding: 30px !important;
  text-align: center;
  height: auto;
  position: relative;
}

.misc-text-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  gap: 1rem;
}

@media screen and (max-width: 768px) {
  .misc-text-container {
    padding-left: 2rem;
    padding-right: 2rem;
    width: auto;
    max-width: none;
  }
}

.css-h3nocp-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected {
  background-color: #9f7fe3 !important;
  color: #fff !important;
}

.css-zz0xjj-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected {
  background-color: #9f7fe3 !important;
  color: #fff !important;
}

.css-1ny3jyh-MuiDateRangePickerDay-root {
  background-color: #f3edff !important;
}

.css-1jt0kfk-MuiDateRangePickerDay-root {
  background-color: #f3edff !important;
}

.css-f8wito-MuiDateRangePickerDay-root {
  background-color: #f3edff !important;
}

.css-1qubkgo-MuiTypography-root-MuiMultiInputDateRangeField-separator {
  margin-top: 20px !important;
}

.create-project .MuiFormLabel-root {
  transform: translate(14px, -12px) scale(0.75);
  background-color: white;
  padding: 0 5px;
}

.create-project .MuiInputBase-root {
  background-color: #f2f2f2 !important;
  font-size: 12px;
}

.create-project .MuiOutlinedInput-root {
  padding: 4px 4px 4px 10px !important;
}
